<template>
  <div class="group-device-list">
    <div class="add-device">
      <div class="groups">
        <div class="check" :class="{ show: isMultiMode }" @click="onSelectAll">
          <img
            v-if="isIndeterminate"
            src="@/assets/icons/tree-indeterminate-yellow.svg"
            alt=""
          />
          <img
            v-else-if="checkAll"
            src="@/assets/icons/checkbox-yellow-checked.svg"
            alt=""
          />
          <img v-else src="@/assets/icons/uncheck.svg" alt="" />
        </div>
        <div class="group-family">
          <div
            class="group-family-item"
            v-for="group in groupFamily(groupId)"
            :key="group.id"
            @click="updateGroupId(group.id)"
          >
            <img src="@/assets/icons/group-icon.svg" alt="" />
            <span class="group-name">{{ group.name }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="device-content">
      <div
        class="row"
        :class="{
          'text-gray': device.enabled === 0,
          // 'text-red': device.locked === 1,
          selected: device.id === deviceId,
          self: isSelf(device)
        }"
        v-for="device in deviceList"
        :key="device.id"
        @click="onSelectDevice(device.id)"
      >
        <div class="col">
          <div
            class="check"
            :class="{ show: isMultiMode }"
            @click.stop="handleCheckDevice(device.id)"
          >
            <img
              v-if="isDeviceChecked(device.id)"
              src="@/assets/icons/checkbox-yellow-checked.svg"
              alt=""
            />
            <img v-else src="@/assets/icons/uncheck.svg" alt="" />
          </div>
        </div>
        <div class="col">
          <template v-if="true">
            <img
              v-if="device.public === euPublic.public"
              class="public-icon"
              src="@/assets/icons/account_detail_public.svg"
              alt=""
            />
            <img
              v-else
              class="public-icon"
              src="@/assets/icons/account_detail_private.svg"
              alt=""
            />
          </template>
          <img class="device-icon" :src="getDeviceIcon(device.id)" alt="" />
          <template v-if="false">
            <img
              v-if="device.public === euPublic.public"
              class="public-icon"
              src="@/assets/icons/account_detail_public.svg"
              alt=""
            />
            <img
              v-else
              class="public-icon"
              src="@/assets/icons/account_detail_private.svg"
              alt=""
            />
          </template>
          <TooltipElement :content="device.id" alwaysShow placement="bottom">
            {{ device.id }}
          </TooltipElement>
        </div>
        <div class="col">
          <TooltipElement
            :content="device.info.name"
            alwaysShow
            placement="bottom"
          >
            {{ device.info.name }}
          </TooltipElement>
        </div>
        <div class="col video-name">
          <TooltipElement
            :content="device.video.title"
            alwaysShow
            placement="bottom"
          >
            {{ device.video.title }}
          </TooltipElement>
        </div>
        <!-- <div class="col">{{ getKeepLabel(device.lprKeepDay) }}</div> -->
        <!-- <div class="col">{{ getKeepLabel(device.videoKeepDay) }}</div> -->
        <div class="col">
          <img
            v-if="device.id === deviceId && device.enabled === 1 && device.locked === 1"
            class="lock"
            src="@/assets/icons/unlocked.svg"
            @click="onUnlock(device)"
            alt=""
          />
          <!-- <img v-if="device.id === deviceId" class="lock" 
            src="@/assets/icons/lock.svg" alt="" @click="unLock(device.id)">-->
          <img
            v-if="isMultiMode && isSelectedDevice(device)"
            :src="getMultiModeStateIconUrl(device)"
            class="multi-mode"
          />
        </div>
      </div>
    </div>
    <PortalEditMessage
      v-if="showPortalEditMessage"
      :title="portal.title"
      :content="portal.content"
      :tmpData="portal.tmpData"
      @close="closePortalEditMessage"
      @confirm="handlePortalEditConfirm"
    />
    <MessageModal
      v-if="bConfirm"
      :header="confirm.header"
      :title="confirm.title"
      :btns="confirm.btns"
      @close="bConfirm = false"
      @cancel="bConfirm = false"
      @confirm="confirm.func"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { apiUnlockUser } from '@/api/index.js'
import { euEditMode, euPublic } from '@/config/account.js'
import { keepOptions } from '@/utils/global.js'
// import MessageModal from '@/components/Base/MessageModal.vue'

export default {
  name: 'DeviceTableList',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
    PortalEditMessage: () =>
      import('@/components/AccountManagement/portal/PortalEditMessage.vue'),
    MessageModal: () => import('@/components/Base/MessageModal.vue'),
  },
  props: {
    deviceList: {
      type: Array,
      default: () => []
    },
    groupId: {
      style: String,
      default: ''
    }
  },
  data() {
    return {
      selectDevices: [],
      getAbnormalAccount: '',
      multiSelect: false,
      keepDayOptions: keepOptions,
      euEditMode,
      euPublic,
      // edit confirm
      showPortalEditMessage: false,
      portal: {
        title: '',
        content: '',
        // action: '',
        tmpData: '',
        actionIndex: -1
      },
      bConfirm: false,
      confirm: {
        header: '',
        title: '',
        btns: '',
        func: () => null
      }
    }
  },
  computed: {
    ...mapState(['permissionV2', 'liveList']),
    ...mapState('userinfo/device', [
      'editMode',
      'deviceId',
      'filterText',
      'selectedDeviceList'
    ]),
    ...mapGetters(['getUserInfo']),
    ...mapGetters('userinfo', ['groupFamily', 'getDeviceIcon']),
    isMultiMode() {
      return this.editMode === euEditMode.multiSelect
    },
    devices() {
      if (!this.deviceList) return []

      const options = this.deviceList.map((device) => {
        return {
          label: device.name,
          value: device.id
        }
      })
      return options
    },
    checkAll() {
      const notCheckList = this.deviceList.filter(
        (device) =>
          !this.selectedDeviceList.find(
            (selectedDev) => device.id == selectedDev.id
          )
      )
      return notCheckList.length === 0
    },
    isIndeterminate() {
      let count = 0
      this.deviceList.forEach((device) => {
        if (
          this.selectedDeviceList.find(
            (selectedDev) => device.id == selectedDev.id
          )
        )
          count++
      })

      return count > 0 && count < this.deviceList.length
    },
    // quotaUsed() {
    //   const quota = this.liveList.filter(
    //     (live) => live.groupId === this.groupId
    //   ).length
    //   return quota
    // },
    // quotaGroup() {
    //   const grpFamilyList = this.groupFamily(this.groupId)
    //   let quota = 0
    //   if (grpFamilyList.length > 0) {
    //     quota = grpFamilyList[grpFamilyList.length - 1].maxIn
    //   }
    //   return quota
    // },
    // quotaTotal() {
    //   const grpFamilyList = this.groupFamily(this.groupId)
    //   let quota = 0
    //   if (grpFamilyList.length > 0) {
    //     quota = grpFamilyList[0].maxIn
    //   }
    //   return quota
    // }
  },
  methods: {
    ...mapMutations('userinfo', [
      'updateDeviceGroupId',
      // 'updateUserId',
      // 'updateSelectedUser',
      'updateAccountPortal',
    ]),
    ...mapActions('userinfo', ['getDeviceInfoList']),
    ...mapMutations('userinfo/device', [
      'updateGroupId',
      'updateDeviceId',
      'updateEditMode',
      'addSelectedDeviceList',
      'removeSelectedDeviceList',
      'updateSelectedDevice',
      'updateAddAccountGroupId'
    ]),
    isSelf(deviceInfo) {
      if (this.getUserInfo) {
        return this.getUserInfo.id === deviceInfo.id
      }
      return false
    },
    isSelectedDevice(deviceInfo) {
      const checked = this.selectedDeviceList.find(
        (dev) => dev.id === deviceInfo.id
      )

      return checked ? checked.state !== -1 : false
    },
    getMultiModeStateIconUrl(deviceInfo) {
      const devIdx = this.selectedDeviceList.findIndex(
        (dev) => dev.id === deviceInfo.id
      )

      if (devIdx < 0) {
        return
      }

      const { state } = this.selectedDeviceList[devIdx]
      switch (state) {
        case 0: // 設定中
          return require('@/assets/icons/loading.svg')
        case 1: // 成功
          return require('@/assets/icons/success-check.svg')
        case 2: // 失敗
          return require('@/assets/icons/failed.svg')
        default:
          return
      }
    },
    getKeepLabel(keepDay) {
      const option = this.keepDayOptions.find(
        (option) => option.value === keepDay
      )
      return option?.label
    },
    onSelectAll() {
      if (this.checkAll) this.removeSelectedDeviceList(this.deviceList)
      else this.addSelectedDeviceList(this.deviceList)
    },
    onMultiSelectDevice(deviceId) {
      this.updateSelectedDevice(deviceId)
    },
    onSelectDevice(deviceId) {
      this.portal.tmpData = deviceId

      this.updateDeviceId(deviceId)
      switch (this.editMode) {
        // case euEditMode.none:
        case euEditMode.editView:
          this.updateEditMode(euEditMode.editView)
          break
        case euEditMode.multiSelect:
          this.onMultiSelectDevice(deviceId)
          break
        default:
          this.portal.title = this.$t('am_edit_not_complete')
          this.portal.content = this.$t('am_cancel_edit')
          // this.portal.action = 'confirm'
          this.portal.actionIndex = -1
          this.updateAccountPortal('portal-edit-message')
          this.showPortalEditMessage = true
      }
    },
    closePortalEditMessage() {
      // keep editMode = euEditMode.editing
      this.showPortalEditMessage = false
    },
    handlePortalEditConfirm() {
      this.showPortalEditMessage = false
      this.updateEditMode(euEditMode.editView)
      this.updateDeviceId(this.portal.tmpData)
    },
    // addDevice(groupId) {
    //   // this.updateDeviceId('') // 先移除先前檢視的設備
    //   this.updateAddAccountGroupId(groupId)

    //   // 要等 Edit 區塊被清完, 再設定新 EditMode
    //   this.updateEditMode(euEditMode.add)
    // },
    isDeviceChecked(deviceId) {
      const index = this.selectedDeviceList.findIndex(
        ({ id }) => id === deviceId
      )
      return index >= 0
    },
    handleCheckDevice(deviceId) {
      this.updateSelectedDevice(deviceId)
    },
    async unLock(deviceId) {
      const res = await apiUnlockUser(deviceId)
      if (res.status === 200) {
        this.$notify({
          type: 'success',
          title: this.$t('account_unlock'),
          message: this.$t('account_unlock_success', {
            name: deviceId
          }),
        })
      } else {
        this.$notify({
          type: 'error',
          title: this.$t('account_unlock'),
          message: this.$t('account_unlock_failed'),
        })
      }

      // 重新取得使用者資訊, 並切換到檢視模式
      await this.getDeviceInfoList()
      this.updateEditMode(euEditMode.editView)
      this.updateDeviceId(deviceId)
      this.bConfirm = false
    },
    onUnlock(account) {
      this.confirm = {
        header: this.$t('account_unlock_confirm_title'),
        title: this.$t('account_unlock_confirm_msg', {name: account.info.name || account.id}),
        btns: { cancel: true, confirm: true },
        func: () => this.unLock(account.id)
      }
      this.bConfirm = true
    }
  }
}
</script>

<style lang="scss" scoped>
$ActionIconW: 3%; // 1rem;
$ShortInfoW: 10%; // 5rem;
$LongInfoW: calc($ShortInfoW * 2.5);
$InfoPadding: 1rem;
$fontSize: px2rem(24);

* {
  box-sizing: border-box;
}
.group-device-list {
  margin-bottom: px2rem(12);

  .add-device {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-left: px2rem(4);
    margin-right: px2rem(13);
    border: 1px solid #4a5c78;
    padding: px2rem(7) px2rem(16) px2rem(7) 0.75rem;
    background: #39425d;
    z-index: 1;

    .groups {
      display: flex;
      align-items: inherit;

      .group-family {
        display: flex;
        align-items: center;
        img {
          width: px2rem(24);
          height: px2rem(24);
          // align-self: flex-end;
        }
        .group-family-item {
          position: relative;
          display: inline-flex;
          align-items: center;
          margin-right: px2rem(36);
          cursor: pointer;

          .group-name {
            position: relative;
            margin-left: px2rem(8);
            border-bottom: 2px solid transparent;
            @include group_name;
            font-size: px2rem(20);

            &:hover {
              border-color: $color_group;
              cursor: pointer;
            }
          }

          &:not(:last-child):before {
            content: '';
            position: absolute;
            left: calc(100% + px2rem(10));
            top: px2rem(6);
            width: px2rem(24);
            height: px2rem(24);
            background-image: url('../../../../assets/icons/RightArrow.svg');
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .device-content {
    margin-left: px2rem(4);
    margin-right: px2rem(13);
    border: 1px solid #4a5c78;
    border-top: none;

    .row {
      display: flex;
      align-items: center;
      // padding: 0 px2rem(4);
      min-height: px2rem(50);

      &:hover {
        background: #6e7d9333;
      }
      &:active {
        background: #6e7d9399;
      }

      &.self {
        background-color: $SelfAccountBGC;
      }

      &.text-gray {
        color: #ffffff33;
      }
      &.text-red {
        color: #f94144;
      }
      &.selected {
        background: #6e7d9399;
        // &.self {
        //   background-color: $SelfAccountBGC;
        // }
      }

      .col:deep {
        display: flex;
        margin-right: 0.4rem;
        padding-left: 0.5rem;
        width: px2rem(24); // 基準
        font-size: px2rem(20);

        // TooltipElement
        .tooltip-wrap {
          display: inline !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        > img {
          margin-right: 0.4rem;
        }
        // 多選帳號 checkbox
        &:first-child {
          margin-left: 0.75rem;
          padding-left: unset;
        }
        // 帳號(包含 帳號ICON, 公開ICON, 帳號ID)
        &:nth-child(2) {
          width: calc($LongInfoW + $ShortInfoW);
        }
        &:nth-child(3),
        &:nth-child(4) {
          width: $LongInfoW;
        }
        // &:nth-child(5),
        // &:nth-child(6) {
        //   width: $ShortInfoW;
        // }
        // Lock, 多選帳號儲存狀態
        &:last-child {
          margin-right: unset;
          padding-left: unset;
        }

        // &.video-name {
        //   display: inline !important;
        //   overflow: hidden;
        //   // white-space: nowrap;
        //   text-overflow: ellipsis;
        // }

        img.multi-mode {
          // margin-right: unset;
          // padding-left: unset;
          width: px2rem(20);
          height: px2rem(20);
          // background-color: #f00;

          &[src*='loading'] {
            animation: rotation 1s infinite linear;
          }
          @keyframes rotation {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }

  .check {
    display: flex;
    margin-right: px2rem(16);
    width: px2rem(20);
    height: px2rem(20);
    cursor: pointer;
    visibility: hidden;

    &.show {
      // margin-bottom: -0.5rem;
      visibility: visible;
    }
  }

  .public-icon {
    margin-top: 0.25rem;
    width: px2rem(19);
    height: px2rem(19);
    &.disabled {
      opacity: 0.2;
    }
  }

  .device-icon {
    width: px2rem(24);
    height: px2rem(24);
  }
}

.lock {
  margin-left: -0.5rem;
  @include filter_F94144;
  cursor: pointer;
}
</style>
