<template>
  <div
    class="state-select-wrap"
    tabindex="0"
    :id="id"
    v-click-outside="onClose"
  >
    <div
      class="title"
      @click="onOpen"
      :class="{ 'show-menu': showMenu, 'has-filter': hasFilter }"
    >
      <img src="@/assets/icons/menu.svg" alt="" />
    </div>
    <div class="menu" ref="menu" v-if="showMenu">
      <div class="close-menu">
        <div @click="onClose">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div
        v-for="(filter, fIdx) of filterList"
        class="state-kind"
        :key="`${filter.key}_${fIdx}`"
      >
        <span>{{ $t(`account_${filter.key}_title`) }}</span>
        <div class="filter-dropdown">
          <div
            class="filter-dropdown-title"
            @click="onOpenDropdown(fIdx)"
            :class="{ 'open-dropdown': filter.dropdown }"
          >
            <span>{{ $t(currOption(filter)) }}</span>
            <img src="@/assets/icons/Drop.svg" alt="" :class="{}" />
            <!-- <i class="fas fa-caret-down"></i> -->
          </div>
          <div class="filter-dropdown-options" v-if="filter.dropdown">
            <div
              class="filter-dropdown-options-wrap"
              v-click-outside="() => onOpenDropdown(fIdx)"
            >
              <div
                v-for="(option, oIdx) in filter.options"
                :key="`${option.label}_${oIdx}`"
                class="filter-dropdown-option"
                :class="{
                  selected: filter.value === option.value
                }"
                @click="onSelectFilter(fIdx, option)"
              >
                {{ $t(`account_${filter.key}_${option.str}`) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import Select from '@/components/AccountManagement/tools/Select.vue'
import { all } from '@/config/account.js'

export default {
  name: 'AccountFilter',
  props: {
    id: {
      type: String,
      default: `filters`
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  comments: { Select },
  data() {
    return {
      showMenu: false,
      filterList: []
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    hasFilter() {
      const usedFilter = this.filterList?.filter(
        (filter) => filter.value !== all
      )
      return usedFilter.length > 0
    },
  },
  methods: {
    currOption(filter) {
      const { key, value, options } = filter
      const option = options.find((o) => o.value === value)

      if (value < 0) {
        return `account_${option.str}`
      } else {
        return `account_${key}_${option.str}`
      }
    },
    onOpen(/*e*/) {
      this.showMenu = !this.showMenu

      if (this.showMenu) {
        this.$nextTick(() => {
          const titleRect = document
            .querySelector('.state-select-wrap .title')
            .getBoundingClientRect()
          const menuRect = document
            .querySelector('.state-select-wrap .menu')
            .getBoundingClientRect()
          const { x, width: titleW, bottom } = titleRect
          const { width: menuW } = menuRect

          let left = x - menuW / 2 + titleW / 2
          let top = bottom + 2
          this.$refs.menu.style.left = left + `px`
          this.$refs.menu.style.top = top + `px`
        })
      }
    },
    onClose() {
      this.showMenu = false
    },
    onOpenDropdown(idx) {
      // Close other dropdown
      this.filterList.forEach((filter, fIdx) => {
        if (idx !== fIdx) {
          this.filterList[fIdx].dropdown = false
        }
      })
      this.filterList[idx].dropdown = !this.filterList[idx].dropdown
    },
    onSelectFilter(idx, option) {
      const oItem = this.filterList[idx].options.find(
        (item) => item.value === option.value
      )
      this.filterList[idx].value = oItem.value

      const newFilterList = JSON.parse(JSON.stringify(this.filterList)).map(
        (filter) => {
          delete filter.dropdown
          return filter
        }
      )
      this.$emit('set', newFilterList)
      this.onOpenDropdown(idx) // close dropdown
    },
  },
  mounted() {
    const tmpFilters = JSON.parse(JSON.stringify(this.filters))
    this.filterList = tmpFilters.map((filter) => {
      return { ...filter, ...{ dropdown: false } }
    })
  },
  watch: {
    filters: {
      deep: true,
      handler(nFilters) {
        nFilters.forEach((nFilter) => {
          const fIdx = this.filterList.findIndex(
            (filter) => filter.key === nFilter.key
          )

          if (fIdx >= 0) {
            this.filterList[fIdx].value = nFilter.value
          }
        })
      }
    },
    showMenu() {
      this.filterList.forEach((filter, idx) => {
        this.filterList[idx].dropdown = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$ElementH: px2rem(52);
$FontSize: px2rem(24);
* {
  box-sizing: border-box;
  user-select: none;
  line-height: initial;
}

.state-select-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: $ElementH;
  margin-bottom: px2rem(4);
  border-radius: 8px;
  font-size: $FontSize;
  font-weight: unset;

  .title {
    display: flex;
    align-items: center;
    padding: px2rem(2) px2rem(14);
    height: 100%;
    line-height: $ElementH;
    border: px2rem(2) solid transparent;
    border-radius: px2rem(8);
    font-size: px2rem(20);
    color: $color_FFF;
    background-color: $color_4A5C78_50; // 50%
    cursor: pointer;

    &.show-menu {
      background-color: $color_4A5C78;
    }

    &.has-filter {
      border-color: $color_FFF;
    }
  }

  .menu {
    position: absolute;
    width: 20%;
    padding: px2rem(8);
    margin-top: 0.25rem;
    border: 1px solid $color_4A5C78;
    border-radius: px2rem(8);
    background-color: $color_151B35;
    z-index: 2;

    .close-menu {
      display: flex;
      justify-content: flex-end;

      div {
        display: flex;
        cursor: pointer;
      }
    }

    .state-kind {
      display: flex;
      flex-direction: column;
      padding-right: px2rem(20);
      padding-bottom: px2rem(24);
      // background-color: #00f;
      font-size: px2rem(20);

      > span {
        padding: px2rem(4) 0 px2rem(8);
        font-weight: 500;
        color: $color_FFF;
      }

      .filter-dropdown {
        display: flex;
        flex-direction: column;
        // background-color: #0f0;

        .filter-dropdown-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: px2rem(3) px2rem(12);
          border-radius: px2rem(8);
          background-color: $color_4A5C78_60;
          cursor: pointer;

          img {
            width: 1rem;
            height: 1rem;
            transform: rotate(0deg);
            transition: 0.3s cubic-bezier(0.75, 0.05, 0.07, 1.05);
          }

          &.open-dropdown {
            img {
              transform: rotate(180deg);
            }
          }
        }
        .filter-dropdown-options {
          position: relative;
          // padding: 1px;
          margin-top: px2rem(4);
          border-radius: 2px;
          background-color: $color_4A5C78_60;
          .filter-dropdown-options-wrap {
            position: absolute;
            width: 100%;
            padding: px2rem(8) 0; // px2rem(12);
            border: 1px solid $color_4A5C78;
            border-radius: 2px;
            background-color: $color_151B35;
            z-index: 1;

            .filter-dropdown-option {
              padding: 0 px2rem(12);
              margin-bottom: px2rem(10);
              &:hover {
                background-color: $color_4A5C78_60;
              }
              &:last-child {
                padding-bottom: 0;
              }
              &.selected {
                background-color: $color_4A5C78_60;
              }
            }
          }
        }
      }
    }
  }
}

</style>
