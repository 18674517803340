<template>
  <div class="device-top">
    <div class="top-filter">
      <div class="input-select">
        <TextSearchBar
          class="text-search"
          ref="testSearch"
          v-model="filterInput"
          :placeholder="$t('am_search_device')"
          @clean="clearInput"
          @search="onSearchAccount"
        />
        <div class="select-model">
          <!-- <Select
            v-model="selectDeviceModels"
            :options="deviceModelOptions"
            :multiSelect="true"
            effect="dark"
            :hasFilter="selectDeviceModels.length > 0"
            :classifySelect="true"
            :placeholder="$t('account_device_model') /*設備類型*/"
          /> -->
          <ClassifySelect
            :title="null"
            :placeholder="null"
            :funName="$t('account_device_model' /*設備類型*/)"
            :disabled="false"
            v-model="selectDeviceModels"
            :options="uiDeviceModelOptions"
            :multiple="true"
            :effect="'dark'"
            :tooltipPlacement="'top'"
            @getFilterText="onGetDeviceModelText"
          />
        </div>
        <!-- 帳號狀態 -->
        <div class="select-status">
          <Select
            v-model="selectDeviceStates"
            effect="dark"
            :options="stateOptions"
            :multiSelect="true"
            :hasFilter="selectDeviceStates.length > 0"
            :placeholder="$t('account_status') /*帳號狀態*/"
          />
        </div>
        <!-- 公開/私有, 勾稽 -->
        <div class="select-more">
          <AccountFilter :filters="filters" @set="onGetFilters" />
        </div>
        <!-- 橡皮擦 -->
        <div
          class="eraser"
          :class="{ disabled: !needEraser }"
          @click="onCleanFilters"
        >
          <img src="@/assets/icons/erase-white.svg" alt="" />
        </div>
        <div class="filter-result">
          {{
            $t('account_filter_result', {
              count: filterResult >= 0 ? filterResult : 0
            }) /*篩選結果*/
          }}
        </div>
      </div>
      <div class="top-filter-right">
        <!-- <div class="filter-result">
          {{
            $t('account_filter_result', {
              count: filterResult >= 0 ? filterResult : 0
            }) /*篩選結果*/
          }}
        </div> -->
      </div>
    </div>
    <div class="top-edit" v-if="showEdit">
      <ToggleSwitch
        v-if="showEdit"
        ref="switch"
        :content="$t('account_multi_select') /*多選帳號*/"
        id="top-switch"
        :value="multiSelectShow"
        @switch="onSwitchMultiple"
      />
      <div class="create-account" v-if="showCreate">
        <div class="create-account-content" @click="onCreateAccount">
          <i class="fas fa-plus"></i>
          <span>{{ $t('account_add') /*新增帳號*/ }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { console } from '@/utils/lib.js'
import {
  all,
  euEditMode,
  euUsedState,
  deviceFilters,
  genDeviceModelOptions,
  deviceModelFilter,
} from '@/config/account.js'
import TextSearchBar from '@/components/tools/TextSearchBar.vue'
import Select from '@/components/AccountManagement/tools/Select.vue'
import AccountFilter from '@/components/AccountManagement/tools/AccountFilters.vue'
import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import ClassifySelect from '@/components/Base/ClassifySelect.vue'

export default {
  name: 'DeviceTop',
  components: {
    TextSearchBar,
    Select,
    AccountFilter,
    ToggleSwitch,
    ClassifySelect
  },
  data() {
    return {
      isDev: console.get(),
      filters: JSON.parse(JSON.stringify(deviceFilters)),
      stateOptions: Object.keys(euUsedState).map((key) => {
        return {
          label: this.$t(`account_status_${key}`),
          value: `${key}`,
          icon: `device_model_0${
            euUsedState[key] === euUsedState.enabled ? '' : '_' + key
          }.svg`
        }
      }),
      multiSelectShow: this.editMode === euEditMode.multiSelect,

      deviceModelFilterText: null
    }
  },
  props: {
    showEdit: {
      type: Boolean,
      default: false
    },
    showCreate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('userinfo', [
      'groupTreeList',
      'deviceList',
      'userInfoList',
      'deviceModels'
    ]),
    ...mapState('userinfo/device', [
      'groupId',
      'editMode',
      'filterText',
      'filterDeviceModels',
      'filterDeviceStates',
      'filterPublic',
      'filterLinkMode',
      'filterResult',
      'deviceId'
    ]),
    ...mapGetters('userinfo/device', ['selectedDeviceInfo']),
    deviceModelOptions() {
      const options = genDeviceModelOptions(this.deviceModels)

      return options.map((_o) => {
        const { classify, label, value, icon } = _o
        let option = {
          classify,
          label: _o.classify ? this.$t(label) : label,
          value,
        }

        if (icon) {
          option = {
            ...option,
            ...{ icon }
          }
        }

        return option
      })
    },
    uiDeviceModelOptions() {
      if (!this.deviceModelFilterText) return this.deviceModelOptions

      const key = this.deviceModelFilterText.toLowerCase()
      const options = [...this.deviceModelOptions]

      return deviceModelFilter(key, options)
    },
    filterInput: {
      get() {
        return this.filterText
      },
      set(value) {
        this.updateFilterText(value)
      }
    },
    selectDeviceModels: {
      get() {
        return this.filterDeviceModels
      },
      set(value) {
        this.updateFilterDeviceModels(value)
      }
    },
    selectDeviceStates: {
      get() {
        return this.filterDeviceStates
      },
      set(value) {
        this.updateFilterDeviceStates(value)
      }
    },
    // multiSelectShow: {
    //   get() {
    //     return this.editMode === euEditMode.multiSelect
    //   },
    //   set(value) {
    //     this.updateEditMode(
    //       value
    //         ? euEditMode.multiSelect
    //         : this.deviceId
    //         ? euEditMode.editView
    //         : euEditMode.none
    //     )
    //   }
    // },
    needEraser() {
      if (
        this.filterText ||
        this.filterDeviceModels.length > 0 ||
        this.filterDeviceStates.length > 0 ||
        this.filterPublic !== all ||
        this.filterLinkMode !== all
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations('userinfo', ['updateDeviceList']),
    ...mapMutations('userinfo/device', [
      'updateEditMode',
      'updateFilterText',
      'updateFilterDeviceModels',
      'cleanFilterDeviceModels',
      'updateFilterDeviceStates',
      'cleanFilterDeviceStates',
      'updateFilterPublic',
      'updateFilterLinkMode',
      'cleanSelectedGroupList',
      'updateAddAccountGroupId',
      'updateMultiSelectMode',
      'clearSelectedDeviceList',
      'updateMultiSelectOnly'
    ]),
    clearInput() {
      this.updateFilterText('')
    },
    searchDevice() {
      // console.log(`[searchDevice]: filterText `, this.filterText)
    },
    onSearchAccount(value) {
      this.updateFilterText(value)
    },
    onGetFilters(newfilters) {
      newfilters.forEach((filter) => {
        if (filter.key === 'public') {
          this.updateFilterPublic(filter.value)
        }
        if (filter.key === 'linkmode') {
          this.updateFilterLinkMode(filter.value)
        }
      })
    },
    onCleanFilters() {
      if (this.needEraser) {
        // 1. 清空 selectedGroupList
        // this.cleanSelectedGroupList() // 清除範圍不要包含 groupTree
        // 2. 清空 filterText
        this.clearInput()
        // 3. 清空 filterDeviceModels
        this.cleanFilterDeviceModels()
        // 4. 清空 filterDeviceStates
        this.cleanFilterDeviceStates()
        // 5. 清空 filterPublic
        this.updateFilterPublic(all)
        // 6. 清空 filterLinkMode
        this.updateFilterLinkMode(all)
      }
    },
    onSwitchMultiple(value) {
      this.multiSelectShow = value

      const editMode = value ? euEditMode.multiSelect : euEditMode.editView
      this.updateEditMode(editMode)

      // 清除 MultiSelectOnly, SelectedDeviceList
      if (!value) {
        this.updateMultiSelectOnly(false)
        this.clearSelectedDeviceList()
      }
    },
    onCreateAccount() {
      // console.time(`Device onCreateAccount`)
      if (this.multiSelectShow) {
        // TBD：增加 取消設定 確認; 因為會從 多選直接切換成新增, 如果沒存多選, 會全部被清掉
        // this.$refs.switch.$emit('switch', false)
        this.onSwitchMultiple(false)
      }

      this.updateEditMode(euEditMode.add)
      if (this.groupId) {
        this.updateAddAccountGroupId(this.groupId)
      } else {
        // 用 groupTree 第1層 當預設群組
        this.updateAddAccountGroupId(
          this.groupTreeList ? this.groupTreeList[0].id : ''
        )
      }
      // console.timeEnd(`Device onCreateAccount`)
    },
    onGetDeviceModelText(filterText) {
      this.deviceModelFilterText = filterText
    }
  },
  watch: {
    editMode(nVal) {
      // console.log(`Device.Top.watch.editMode:`, Object.keys(euEditMode)[this.editMode])
      this.multiSelectShow = nVal === euEditMode.multiSelect
    },
    filterPublic(nVal) {
      const publicIdx = this.filters.findIndex(({ key }) => key === 'public')

      if (publicIdx >= 0) {
        this.filters[publicIdx].value = nVal
      }
    },
    filterLinkMode(nVal) {
      const linkModeIdx = this.filters.findIndex(
        ({ key }) => key === 'linkmode'
      )

      if (linkModeIdx >= 0) {
        this.filters[linkModeIdx].value = nVal
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.device-top {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: px2rem(12);
  border-bottom: 1px solid #4a5c78;
  color: #ffffff;

  $DropDownMaxW: calc(100vw * 0.16);
  .top-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: px2rem(8) 0 px2rem(8) px2rem(8);

    .input-select {
      // width: 80%;
      display: flex;

      .text-search:deep {
        display: flex;
        align-items: center;
        width: px2rem(400);
        height: px2rem(48);
        margin-right: px2rem(12);
      }

      .select-model:deep {
        min-width: px2rem(120);
        max-width: $DropDownMaxW;
        margin-right: px2rem(12);

        .disabled {
          opacity: 0.6;
        }

        .select-options {
          width: 160%;
        }

        .classify-select-wrap {
          .label-wrap {
            height: px2rem(48); // legacy
          }
          .dropdown-wrap {
            width: $DropDownMaxW;
          }
        }
      }

      .select-status {
        min-width: px2rem(120);
        max-width: $DropDownMaxW;
        margin-right: px2rem(12);
      }

      .select-more {
        min-width: px2rem(42);
        margin-right: px2rem(12);
      }

      .eraser {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        margin-top: 0.4rem;
        padding: 0.5rem;
        height: px2rem(48);
        width: px2rem(48);
        &.disabled {
          opacity: 0.2;
          &:hover {
            background-color: unset;
            cursor: unset;
          }
        }
        &:hover {
          background-color: #4a5c7880; // 50%
          cursor: pointer;
        }

        img {
          height: px2rem(24);
          width: px2rem(24);
        }
      }

      .filter-result {
        margin-left: px2rem(12);
        padding-top: 1rem;
        font-size: px2rem(20);
      }
    }

    .top-filter-right {
      display: flex;
      justify-content: flex-end;

      // .filter-result {
      //   padding-top: px2rem(12);
      //   font-size: px2rem(20);
      // }
    }
  }

  .top-edit {
    display: flex;
    justify-content: flex-end;
    .create-account {
      margin-left: 1.5rem;
      cursor: pointer;

      .create-account-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        border-radius: 0.5rem;
        font-size: px2rem(24);
        background-color: #4a5c7880;

        &:hover {
          background-color: #4a5c78;
        }
        // &:lang(ja) {
        //   font-size: px2rem(14);
        // }

        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.wrap-switch {
  color: #ffffff;
  display: flex;
  align-items: center;
  /* width: 30%; */
}

.btn {
  display: inline-block;
  background: #4a5c7880;
  border-radius: px2rem(8);
  font-size: px2rem(24);
  font-weight: 300;
  line-height: px2rem(35);
  padding: px2rem(7) px2rem(24) px2rem(6);
  cursor: pointer;
  /* margin-top: px2rem(30);
  margin-left: px2rem(280); */
  text-align: center;
  width: px2rem(144);
}
.btn:hover {
  background: #4a5c78;
}

.account-switch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: px2rem(19);
  padding: px2rem(8) px2rem(15) px2rem(8) px2rem(20);
}
.account-switch .slider {
  position: relative;
  display: inline-block;
  height: px2rem(12);
  width: px2rem(25);
  border-radius: px2rem(20);
  cursor: pointer;
  background: #546267;
  transition: all 0.2s cubic-bezier(0.75, 0.05, 0.07, 1.05);
}
.account-switch .slider:after {
  background: #fefefe;
  position: absolute;
  left: px2rem(-8);
  top: px2rem(-2);
  display: block;
  width: px2rem(17);
  height: px2rem(17);
  border-radius: 50%;
  box-shadow: 0px px2rem(2) px2rem(2) rgba(0, 0, 0, 0.2);
  content: '';
  transition: all 0.2s;
}
.account-switch .label {
  font-size: px2rem(24);
  color: #ffffff;
  margin-right: px2rem(16);
  line-height: px2rem(28);
  white-space: nowrap;
}
.account-switch .input {
  display: none;
}

.account-switch .input:checked ~ .slider:after {
  left: px2rem(16);
}
.account-switch .input:checked ~ .slider {
  background: #0096c7;
}
</style>
