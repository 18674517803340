<template>
  <div class="wrap-device2" :style="{ '--resize-leave': `${resize_leave}px` }">
    <div class="group-wrap" ref="group">
      <DeviceGroupTree class="group-tree-block" :showCreate="showCreate" />
      <button class="divider-bar" ref="divider" @mousedown="onStartResize">
        <div class="vertical-divider"></div>
      </button>
    </div>
    <div class="container-device">
      <DeviceTop
        class="container-device-top"
        :showEdit="showEdit"
        :showCreate="showCreate"
      />
      <div class="container-device-detail" :showEdit="showEdit">
        <DeviceTable class="device-table" />
        <div class="vertical-line"></div>
        <DeviceEdit class="device-detail" :showEdit="showEdit" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { console } from '@/utils/lib.js'
import { PermissionLevel } from '@/config/permissions.js'
import { all, euEditMode } from '@/config/account.js'

import DeviceGroupTree from './device/GroupTree.vue'
import DeviceTop from './device/Top.vue'
import DeviceTable from './device/Table.vue'
import DeviceEdit from './device/Edit.vue'

export default {
  name: 'Device',
  components: {
    DeviceGroupTree,
    DeviceTop,
    DeviceTable,
    DeviceEdit
  },
  data() {
    return {
      isDev: console.get(),
      resizing: false,
      startX: 0,
      resize_leave: 2,
    }
  },
  computed: {
    ...mapState(['permissionV2']),
    showEdit() {
      return this.permissionV2.device >= PermissionLevel.L2
    },
    showCreate() {
      return this.permissionV2.device >= PermissionLevel.L4
    }
  },
  methods: {
    ...mapMutations('userinfo/device', [
      // DeviceGroupTree
      'updateGroupId',
      'cleanSelectedGroupList',
      // DeviceTop
      'updateFilterText',
      'cleanFilterDeviceModels',
      'cleanFilterDeviceStates',
      'updateFilterPublic',
      'updateFilterLinkMode',

      // Edit
      'updateEditMode',
      // Edit - Single
      'updateDeviceId',
      'updateSelectedDevice',
      // Edit - New
      'updateAddAccountGroupId',
      // Edit - Multiple
      'clearSelectedDeviceList',
      'updateMultiSelectOnly'
    ]),
    init() {
      // init: 清除 store.userinfo.device 的資料

      // DeviceGroupTree
      this.updateGroupId('')
      this.cleanSelectedGroupList()
      // DeviceTop
      this.updateFilterText('')
      this.cleanFilterDeviceModels()
      this.cleanFilterDeviceStates()
      this.updateFilterPublic(all)
      this.updateFilterLinkMode(all)

      // Edit
      this.updateEditMode(euEditMode.editView)
      // Edit - Single
      this.updateDeviceId('')
      this.updateSelectedDevice('')
      // Edit - New
      this.updateAddAccountGroupId('')
      // Edit - Multiple
      this.clearSelectedDeviceList()
      this.updateMultiSelectOnly(false)
    },
    onStartResize(event) {
      this.resizing = true
      this.startX = event.clientX

      document.addEventListener('mousemove', this.onResizeMove)
      document.addEventListener('mouseup', this.onStopResize)
    },
    onResizeMove(event) {
      if (this.resizing) {
        const fullGroupWrap = this.$refs.group
        const deltaX = event.clientX - this.startX
        let newWidth = fullGroupWrap.getBoundingClientRect().width + deltaX

        fullGroupWrap.style.width = `${Math.max(newWidth, this.resize_leave)}px`
        this.startX = event.clientX
      }
    },
    onStopResize() {
      this.resizing = false

      document.removeEventListener('mousemove', this.onResizeMove)
      document.removeEventListener('mouseup', this.onStopResize)
    }
  },
  created() {
    console.time(`Device.created`)
    this.init()
    console.timeEnd(`Device.created`)
  },
  mounted() {
    console.time(`Device.mounted`)
    console.timeEnd(`Device.mounted`)
  },
  // destroyed() {}
}
</script>

<style lang="scss" scoped>
$NavH: 70px;
$groupTreeW: 15%;
$topMaxH: px2rem(160); // fit-content;
$topMinH: px2rem(89);
$tableW: 60%;
$detailW: calc(100% - $tableW);
$dividerW: var(--resize-leave);

* {
  box-sizing: border-box;
}

.wrap-device2 {
  display: flex;
  width: 100%;
  height: 100%;

  .group-wrap {
    display: flex;
    width: $groupTreeW;
    .group-tree-block {
      width: calc(100% - $dividerW);
      overflow: hidden;
    }

    .divider-bar {
      height: 100%;
      width: calc($dividerW * 1.5);
      background-color: transparent;
      cursor: col-resize;

      .vertical-divider {
        @include vertical_divider($color_4A5C78, $dividerW);
      }
    }
  }
  .container-device {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // width: calc(100% - $groupTreeW);
    height: calc(100vh - $NavH);

    .container-device-top {
      min-height: $topMinH;
      max-height: $topMaxH;
    }

    .container-device-detail {
      display: flex;
      width: 100%;
      height: calc(100% - $topMinH + px2rem(8));

      &[showEdit='true'] {
        height: calc(100% - $topMaxH + px2rem(13));
      }

      .device-table {
        width: calc($tableW - 1px);
      }
      .vertical-line {
        @include vertical_divider($color_4A5C78, 2px);
      }
      .device-detail {
        width: calc($detailW - 1px);
      }
    }
  }
}
</style>
