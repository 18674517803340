<template>
  <div class="wrap-multi-device-edit">
    <!-- <div class="select-account">
      <div class="title">
        {{ $t('account_selected') /*已選帳號*/ }} ({{
          selectedDeviceList.length
        }})
        <img
          v-if="selectedDeviceList.length > 0"
          src="@/assets/icons/TrashCan.svg"
          alt=""
          @click="clearAllSelectedDevice"
        />
      </div>
      <div class="account-list">
        <div
          class="account"
          v-for="(deviceId, index) in selectedDeviceList"
          :key="deviceId"
          :class="{
            selected: deviceId === selectedDevice,
            'text-gray': isDisabled(deviceId) || isSuspended(deviceId),
            'text-red': isLocked(deviceId)
          }"
          @click="selectedDevice = deviceId"
          @mouseover="deviceMouseOver(deviceId)"
          @mouseleave="deviceMouseLeave"
        >
          <div v-if="statusList.length > 0" class="status">
            <img
              v-if="statusList[index] === 'loading'"
              class="loading"
              src="@/assets/icons/loading.svg"
              alt=""
            />
            <img
              v-else-if="statusList[index] === 'success'"
              class="success"
              src="@/assets/icons/success-check.svg"
              alt=""
            />
            <img v-else src="@/assets/icons/failed.svg" alt="" />
          </div>
          <div class="id">
            <img alt="" :src="getDeviceIcon(deviceId)" />
            {{ `${getUserName(deviceId)}(${deviceId})` }}
          </div>
          <div
            v-show="hoverId === deviceId"
            class="remove"
            @click="removeDeviceId(deviceId)"
          >
            <img src="@/assets/icons/close.svg" alt="" />
          </div>
        </div>
      </div>
    </div> -->

    <div class="batch-edit">
      <div class="batch-ctrl" :class="{ disabled: disableCtrl }">
        <div class="filter-switch">
          <ToggleSwitch
            v-model="multiSelectOnly"
            class="switch"
            id="selected_only"
            :content="
              $t('account_batch_selected_account_only', {
                count: selectedDeviceList.length
              })
            "
            :disabled="disableCtrl"
            @switch="onSwitchSelectedOnly"
          />
        </div>
        <!-- <div
          class="clean-all-user"
          @click="clearAllSelectedDevice"
          :class="{ disabled: disableCtrl }"
        >
          <img src="@/assets/icons/erase-white.svg" alt="" />
        </div> -->
      </div>
      <div class="header">
        <div class="title">
          {{ $t('account_batch_edit_items') /*批次修改項目*/ }}
        </div>
      </div>
      <div class="edit-items">
        <div
          class="item"
          v-for="(item, idx) in deviceItems"
          :key="`${item.title}_${idx}`"
        >
          <div class="item-block">
            <div class="item-detail">
              <div class="checked" @click="onSelectBatchItem(idx)">
                <img
                  v-if="batchItems[idx].checked"
                  src="@/assets/icons/checkbox-yellow-checked.svg"
                  alt=""
                />
                <img v-else src="@/assets/icons/uncheck.svg" alt="" />
              </div>
              <div
                class="value-wrap"
                :class="{ disabled: !batchItems[idx].checked }"
              >
                <div class="title">
                  <img v-if="item.ui" :src="item.icon" alt="" />
                  <div>{{ $t(item.title) }}</div>
                </div>
                <div class="value">
                  <!-- 公開狀態 -->
                  <Select
                    v-if="item.key === 'public'"
                    class="dropdown"
                    v-model="item.editValue"
                    :isDisabled="!batchItems[idx].checked"
                    :options="formatOptions(item.options)"
                  />
                  <!-- 設備類型 -->
                  <div
                    v-else-if="item.key === 'deviceModelId'"
                    class="device-model"
                  >
                    <!-- <img v-if="item.editValue" class="edit" :src="getDeviceModelIcon(item)" alt="" />
                    <Select
                      class="dropdown device-model"
                      v-model="item.editValue"
                      :classifySelect="true"
                      :isDisabled="!batchItems[idx].checked"
                      :options="deviceModelOptions"
                      :placeholder="$t('search_device_placehold') /*請選擇*/"
                    /> -->
                    <ClassifySelect
                      :title="null"
                      :placeholder="$t('search_device_placehold') /*請選擇*/"
                      :funName="null"
                      :disabled="!batchItems[idx].checked"
                      v-model="item.editValue"
                      :options="uiDeviceModelOptions"
                      :multiple="false"
                      :effect="'light'"
                      :tooltipPlacement="'top'"
                      @getFilterText="onGetDeviceModelText"
                    />
                  </div>
                  <!-- 勾稽 -->
                  <Select
                    v-else-if="item.key === 'linkedMode'"
                    class="dropdown"
                    v-model="item.editValue"
                    :isDisabled="!batchItems[idx].checked"
                    :options="formatOptions(item.options)"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="item-error"></div> -->
          </div>
          <div class="item-helper"></div>
        </div>
      </div>
      <div class="footer">
        <div class="btn cancel" @click="onCancel">
          {{ $t('cancel') }}
        </div>
        <div
          class="btn confirm"
          @click="onEditDevice"
          :class="{ disabled: disableFooter }"
        >
          {{ $t('confirm') }}
        </div>
      </div>
    </div>
    <PortalCreateConfirm
      v-if="msgPortal.show"
      :title="$t('account_edit_title')"
      :content="$t('account_edit_device_model_hint')"
      :action="false"
      @close="() => (msgPortal.show = false)"
      @confirm="onConfirmMsgPotral"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { apiEditUser } from '@/api/index.js'
import { console /*, getAncestorList*/ } from '@/utils/lib.js'
import {
  euEditMode,
  euPublic,
  euLinkMode,
  multiDeviceItems as defDeviceItems,
  genDeviceModelOptions,
  deviceModelFilter,
} from '@/config/account.js'
import ToggleSwitch from '@/components/tools/ToggleSwitch.vue'
import Select from '../../tools/Select.vue'
import PortalCreateConfirm from '../../portal/PortalCreateConfirm.vue'
import ClassifySelect from '@/components/Base/ClassifySelect.vue'

export default {
  name: 'DeviceEditMulti',
  components: { ToggleSwitch, Select, PortalCreateConfirm, ClassifySelect },
  data() {
    return {
      isDev: console.get(),
      deviceItems: [],
      batchItems: defDeviceItems.map(({ key }) => {
        return { key, checked: false }
      }),
      selectedDevice: '',
      hoverId: '',
      statusList: [],

      isTypeOptionsShow: false,
      editDeviceTypeId: '',

      deviceModelFilterText: null,

      // PortalMessage
      msgPortal: {
        show: false,
        param: null
      }
    }
  },
  computed: {
    ...mapState('userinfo', ['userInfoList', 'deviceTypeList', 'deviceModels']),
    ...mapState('userinfo/device', [
      'editMode',
      'selectedDeviceList',
      'multiSelectOnly'
    ]),
    ...mapGetters('userinfo', ['getUserName', 'getDeviceIcon']),
    editDeviceType() {
      if (!this.editDeviceTypeId) return {}

      return {} // this.deviceTypeList.find((type) => type.id === this.editDeviceTypeId)
    },
    disableCtrl() {
      return this.selectedDeviceList.length <= 0
    },
    checkItems() {
      const publicIdx = this.batchItems.findIndex(({ key }) => key === 'public')
      const hasPublic = this.batchItems[publicIdx].checked

      const linkedModeIdx = this.batchItems.findIndex(
        ({ key }) => key === 'linkedMode'
      )
      const hasLinkedMode = this.batchItems[linkedModeIdx].checked

      const deviceModelIdIdx = this.batchItems.findIndex(
        ({ key }) => key === 'deviceModelId'
      )
      const hasDeviceModelId = this.batchItems[deviceModelIdIdx].checked

      return { hasPublic, hasLinkedMode, hasDeviceModelId }
    },
    disableFooter() {
      const { hasPublic, hasLinkedMode, hasDeviceModelId } = this.checkItems
      const hasDevice = this.selectedDeviceList.length > 0

      // TODO：設備類型
      return !((hasPublic || hasLinkedMode || hasDeviceModelId) && hasDevice)
    },
    deviceModelOptions() {
      const options = genDeviceModelOptions(this.deviceModels)

      return options.map((_o) => {
        const { classify, label, value, icon } = _o
        let option = {
          classify,
          label: _o.classify ? this.$t(label) : label,
          value,
        }

        if (icon) {
          option = {
            ...option,
            ...{ icon }
          }
        }

        return option
      })
    },
    uiDeviceModelOptions() {
      if (!this.deviceModelFilterText) return this.deviceModelOptions

      const key = this.deviceModelFilterText.toLowerCase()
      const options = [...this.deviceModelOptions]

      return deviceModelFilter(key, options)
    },
  },
  methods: {
    ...mapActions('userinfo', ['getDeviceInfoList']),
    ...mapMutations('userinfo', ['updateAccountPortal']),
    ...mapMutations('userinfo/device', [
      'clearSelectedDeviceList',
      'updateSelectedDeviceList',
      'updateMultiSelectOnly',
      'updateEditMode',
      'updateSelectedDeviceState'
    ]),

    /*// isLocked(deviceId) {
    //   return this.userInfoList.find((user) => user.id === deviceId)?.locked ===
    //     1
    //     ? true
    //     : false
    // },
    // isDisabled(deviceId) {
    //   return this.userInfoList.find((user) => user.id === deviceId)?.enabled !==
    //     1
    //     ? true
    //     : false
    // },
    // isSuspended(deviceId) {
    //   return this.userInfoList.find((user) => user.id === deviceId)
    //     ?.suspendedTime
    //     ? true
    //     : false
    // },
    // deviceMouseOver(deviceId) {
    //   this.hoverId = deviceId
    // },
    // deviceMouseLeave() {
    //   this.hoverId = ''
    // },
    // removeDeviceId(deviceId) {
    //   if (deviceId === this.selectedDevice) {
    //     this.selectedDevice = ''
    //   }
    //   this.updateSelectedDeviceList(deviceId)
    // },
    // handleOptions() {
    //   this.isTypeOptionsShow = !this.isTypeOptionsShow
    // },
    // setEditDeviceTypeId(typeId) {
    //   this.editDeviceTypeId = typeId
    //   this.isTypeOptionsShow = false
    // },*/
    formatOptions(options) {
      return options.map((option) => {
        return { ...option, ...{ label: this.$t(option.label) } }
      })
    },
    onSwitchSelectedOnly() {
      this.updateMultiSelectOnly(!this.multiSelectOnly)
    },
    clearAllSelectedDevice() {
      if (this.disableCtrl && this.disableFooter) {
        return
      }

      this.selectedDevice = ''
      this.clearSelectedDeviceList()
    },
    onSelectBatchItem(idx) {
      this.batchItems[idx].checked = !this.batchItems[idx].checked
    },
    getDeviceModelIcon(item) {
      const currValue = item.editValue
      const option = this.deviceModelOptions.find(
        ({ value, classify }) => !classify && value === currValue
      )
      return option ? require(`@/assets/icons/${option.icon}`) : null
    },
    onCancel() {
      this.clearAllSelectedDevice()
      this.updateEditMode(euEditMode.editView)
    },
    async editDevice(id, editPublic, editLinkMode, editDeviceModelId) {
      const { hasPublic, hasLinkedMode, hasDeviceModelId } = this.checkItems
      let param = { id }

      if (hasPublic) {
        param = { ...param, ...{ public: editPublic } }
      }
      if (hasLinkedMode) {
        param = { ...param, ...{ linkedMode: editLinkMode } }
      }
      if (hasDeviceModelId) {
        param = { ...param, ...{ deviceModelId: editDeviceModelId } }
      }

      // API
      return await apiEditUser(param)
    },
    async apiEditDevice() {
      const editPublic = this.deviceItems.find(
        ({ key }) => key === 'public'
      ).editValue
      const editLinkMode = this.deviceItems.find(
        ({ key }) => key === 'linkedMode'
      ).editValue
      const editDeviceModelId = this.deviceItems.find(
        ({ key }) => key === 'deviceModelId'
      ).editValue

      const results = await Promise.all(
        this.selectedDeviceList.map(async (user) => {
          return this.editDevice(
            user.id,
            editPublic,
            editLinkMode,
            editDeviceModelId
          )
        })
      )

      // 重新取得userInfoList
      this.getDeviceInfoList()

      let nSuccess = 0
      let nFailed = 0

      results.forEach((res) => {
        const { status, config } = res
        const data = JSON.parse(config.data)

        if (status === 200) {
          this.updateSelectedDeviceState({ id: data.id, state: 1 }) // 成功
          nSuccess++
        } else {
          this.updateSelectedDeviceState({ id: data.id, state: 2 }) // 失敗
          nFailed++
        }
      })

      this.$notify({
        title: this.$t('account_batch_modified'),
        // message: `${nSuccess}${this.$t('account_n_success')}，${nFailed}${this.$t('account_n_failed')}。`,
        message: this.$t('account_batch_n', {
          pass_count: nSuccess,
          fail_count: nFailed
        }),
        type: 'success',
      })
    },
    async onEditDevice() {
      if (this.disableFooter) {
        return
      }

      // 初始狀態：設定中(0)
      this.selectedDeviceList.forEach((dev) => {
        const newDev = { ...dev, ...{ state: 0 } }
        this.updateSelectedDeviceState(newDev)
      })

      const { hasDeviceModelId } = this.checkItems
      if (hasDeviceModelId) {
        this.updateAccountPortal('account-create-confirm')
        this.msgPortal.show = true
        return
      }

      // API ---
      await this.apiEditDevice()
    },
    async onConfirmMsgPotral() {
      this.msgPortal.show = false
      await this.apiEditDevice()
    },
    onGetDeviceModelText(filterText) {
      this.deviceModelFilterText = filterText
    }
  },
  watch: {
    selectedDeviceList() {
      if (this.selectedDeviceList.length <= 0) {
        this.selectedDevice = ''
      }
    },
    // editMode(nVal) {
    //   console.log(`Device.Edit.Multi.editMode:`, Object.keys(euEditMode)[nVal])
    // }
  },
  mounted() {
    this.deviceItems = defDeviceItems.map((item) => {
      const { key } = item
      if (key === 'public') {
        item.editValue = euPublic.private
      } else if (key === 'linkedMode') {
        item.editValue = euLinkMode.not_link
      } else if (key === 'deviceModelId') {
        item.editValue = ''
      }

      return item
    })
  },
  destroyed() {
    // 清除 ...
    this.updateMultiSelectOnly(false)
    this.userItems = defDeviceItems.map((item) => {
      const { key } = item
      if (key === 'public') {
        item.editValue = euPublic.private
      } else if (key === 'linkedMode') {
        item.editValue = euLinkMode.not_link
      } else if (key === 'deviceModelId') {
        item.editValue = ''
      }

      return item
    })
    this.clearSelectedDeviceList()
  }
}
</script>

<style lang="scss" scoped>
$SelectAccountH: 0%; // 46%;

* {
  box-sizing: border-box;
}

.wrap-multi-device-edit {
  width: 100%;
  height: 100%;
  background: #282942;
  color: #ffffff;
}

// .select-account {
//   height: 46%;
//   border-bottom: 1px solid #4a5c78;

//   .title {
//     font-size: 24px;
//     padding: px2rem(6) px2rem(12) px2rem(6) px2rem(16);
//     display: flex;
//     justify-content: space-between;
//     align-items: center;

//     img {
//       position: relative;
//       left: px2rem(-14);
//       cursor: pointer;
//     }
//   }

//   .account-list {
//     height: calc(100% - px2rem(80));
//     overflow: overlay;

//     .account {
//       display: flex;
//       align-items: center;
//       font-size: px2rem(24);
//       font-weight: 300;
//       line-height: px2rem(35);
//       padding: 8px 0;
//       margin-right: px2rem(14);
//       cursor: pointer;
//       position: relative;

//       &:hover {
//         background: #6e7d9333;
//       }
//       &:active,
//       &.selected {
//         background: #6e7d9399;
//       }
//       &.text-gray {
//         color: #ffffff33;
//       }
//       &.text-red {
//         color: #f94144;
//       }

//       .status {
//         position: absolute;
//         left: px2rem(20);
//         top: px2rem(7);

//         .loading {
//           animation: rotation 1s infinite linear;
//         }

//         @keyframes rotation {
//           from {
//             transform: rotate(0deg);
//           }
//           to {
//             transform: rotate(360deg);
//           }
//         }

//         .success {
//           width: px2rem(20);
//           height: px2rem(20);
//         }
//       }

//       .id {
//         display: flex;
//         padding-left: px2rem(56);
//         width: 50%;

//         img {
//           margin-right: px2rem(12);
//         }
//       }

//       .remove {
//         position: absolute;
//         right: px2rem(20);
//         width: px2rem(24);
//         height: px2rem(24);
//         background: #f94144;
//         border-radius: px2rem(2);
//         display: flex;
//         justify-content: center;
//         align-items: center;

//         img {
//           width: px2rem(12);
//           height: px2rem(12);
//         }
//         &:hover {
//           background: #ffffff;
//           img {
//             filter: brightness(0) saturate(100%) invert(32%) sepia(54%)
//               saturate(3448%) hue-rotate(338deg) brightness(102%) contrast(97%);
//           }
//         }
//       }
//     }
//   }
// }

.batch-edit {
  display: flex;
  flex-direction: column;
  height: calc(100% - $SelectAccountH);
  padding-top: 1rem;
  padding-left: 1rem;

  .batch-ctrl {
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
    &.disabled {
      opacity: 0.2;
    }
    .filter-switch {
      display: flex;
      margin-left: 0.5rem;
      margin-bottom: 2rem;
    }

    .clean-all-user {
      cursor: pointer;
      &.disabled {
        // opacity: 0.2;
        cursor: unset;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: px2rem(12);
    .title {
      padding: 0;
      font-size: px2rem(24);
      font-weight: 500;
      color: #ffe99f;
    }
  }

  .edit-items {
    height: calc(100% - px2rem(120));
    // padding: px2rem(20) px2rem(20) px2rem(12);

    .item {
      $HelperW: px2rem(48);
      display: flex;
      margin-top: px2rem(12);

      .item-block {
        display: flex;
        flex-direction: column;
        width: calc(100% - $HelperW);

        .item-detail {
          $CheckW: calc(px2rem(20) + 1rem);
          $TitleW: calc(25% * 1.5);

          display: flex;
          align-items: center;
          // background-color: #f00;
          .checked {
            display: flex;
            padding-right: 1rem;
            width: $CheckW;
            img {
              cursor: pointer;
            }
          }

          .value-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: calc(100% - $CheckW);
            height: 100%;
            border-radius: px2rem(8);
            font-size: px2rem(20);
            // font-size: px2rem(24);
           .title {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: $TitleW;
              height: inherit;
              border: 1px solid #ffffff80;
              border-right: none;
              border-top-left-radius: px2rem(10);
              border-bottom-left-radius: px2rem(10);
              padding: px2rem(6) px2rem(12) px2rem(6) px2rem(16);
              color: #ffffff;
              background: #282942;

              img {
                padding-right: px2rem(8);
              }
            }

            .value {
              $LeftGap: 1rem;
              display: flex;
              align-items: center;

              width: calc(100% - $TitleW);
              height: inherit;
              padding-left: $LeftGap;
              border: 1px solid #ffffff80;
              border-left: none;
              border-top-right-radius: px2rem(10);
              border-bottom-right-radius: px2rem(10);
              background-color: #ffffff;
              // background-color: #00f;

              .switch {
                padding-left: 0.5rem;
              }

              .dropdown:deep {
                display: flex;
                .select {
                  height: 100%;
                  .select-selected {
                    padding-left: unset;
                    padding-right: px2rem(12);
                    height: 100%;
                    text-align: unset;
                    // font-size: px2rem(16);

                    img {
                      @include img_icon_16x16;
                    }
                  }
                }
              }

              .device-model:deep {
                display: flex;
                align-items: center;
                margin-left: px2rem(-12);
                width: 100%;

                .classify-select-wrap {
                  .label-wrap {
                    .label-content.light {
                      img {
                        width: 1.5rem;
                        height: 1.5rem;
                      }
                    }
                  }
                }
              }
            }

            &.disabled {
              opacity: 0.2;
            }
          }
        }
        // .item-error {}
      }

      .item-helper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $HelperW;
        // background-color: #00f;
      }
    }
  }
}

.footer {
  height: px2rem(120);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #4a5c78;

  .btn {
    border: 1px solid #ffc600;
    border-radius: px2rem(8);
    padding: px2rem(10) px2rem(48);
    margin-left: px2rem(16);
    margin-right: px2rem(16);
    font-size: 1.5rem;
    white-space: nowrap;
    cursor: pointer;

    &.cancel {
      color: #ffc600;
      &:hover {
        background: #ffc60033;
      }
    }

    &.confirm {
      background: #ffc600;
      color: #282942;
      &:hover {
        background: #ffd133;
      }

      &.disabled {
        opacity: 0.2;
        cursor: unset;
        &:hover {
          background: #ffc600;
        }
      }
    }
  }
}
</style>
