import { isUser, isDevice } from './global.js'

export function getGroupLen(nodeTreeList) {
  if (nodeTreeList.length <= 0) return 0

  let groups = 0

  nodeTreeList.forEach((childNode) => {
    groups += getNodeGroups(childNode)
  })

  return groups
}

// 計算每個群組的 groups, users, devices +++
function getNodeGroups(node) {
  if (!node.children) return 0

  let groups = 1
  node.children.forEach((childNode) => {
    // if (childNode.uiIsGroup) groups++
    groups += getNodeGroups(childNode)
  })

  return groups
}
export function nodeGroups(node) {
  const groups = getNodeGroups(node)
  return groups ? groups - 1 /*不含自己*/ : groups
}

function getNodeUsers(node, onlyUser) {
  if (!node.children) return 0

  let users = 0
  node.children.forEach((childNode) => {
    let matchKind = onlyUser
      ? isUser(node.kind)
      : childNode.kind === 1 || childNode.kind === 3 // kind === default 不列入計算

    if (!childNode.uiIsGroup && matchKind) {
      users++
    }
    users += getNodeUsers(childNode, onlyUser)
  })
  return users
}
export function nodeUsers(node, onlyUser) {
  return getNodeUsers(node, onlyUser)
}

function getNodeDevices(node) {
  if (!node.children) return 0

  let devices = 0
  node.children.forEach((childNode) => {
    if (!childNode.uiIsGroup && isDevice(childNode.kind)) {
      devices++
    }
    devices += getNodeDevices(childNode)
  })
  return devices
}
export function nodeDevices(node) {
  return getNodeDevices(node)
}

// 計算每個群組(累積)的 發布/收看 數 +++
function getNodeOutIn(node, outOrIn) {
  if (!node.children || !node.uiIsGroup) return 0

  let outs = node[`max${outOrIn}`]
  node.children.forEach((childNode) => {
    if (childNode.uiIsGroup) {
      outs += childNode[`max${outOrIn}`]
    }
    outs += getNodeOutIn(childNode, outOrIn)
  })
  return outs
}
export function nodeOutIn(node, outOrIn) {
  return getNodeOutIn(node, outOrIn)
}
