<template>
  <div class="wrap-device-table">
    <div class="device-table">
      <div class="header">
        <div class="row">
          <div
            class="col"
            :class="{
              eraser: isMultiMode,
              disabled: this.selectedDeviceList.length <= 0
            }"
            @click="clearSelectedDeviceList()"
          >
            <img src="@/assets/icons/erase-white.svg" alt="" />
          </div>
          <div class="col id">{{ $t('account_title') }}</div>
          <div class="col">{{ $t('am_device_name') }}</div>
          <div class="col">{{ $t('am_device_video_name') }}</div>
          <!-- <div class="col">{{ $t('am_device_event_keep') }}</div> -->
          <!-- <div class="col">{{ $t('am_device_video_keep') }}</div> -->
          <div class="col">&nbsp;</div>
        </div>
      </div>
      <div class="device-content">
        <template v-for="group in subGroupList">
          <DeviceTableList
            :key="group.id"
            :deviceList="getFilterGroupDeviceList(group.id)"
            :groupId="group.id"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { euAccountKind } from '@/utils/global.js'
import { getNodeFamily } from '@/utils/lib.js'
import {
  all,
  euEditMode,
  // euUsedState,
  // deviceFilters
} from '@/config/account.js'
import DeviceTableList from './Table/DeviceList.vue'

export default {
  name: 'DeviceTable',
  components: { DeviceTableList },
  // props: ['showCreate'],
  data() {
    return {}
  },
  computed: {
    ...mapState('userinfo', [
      'deviceInfoList',
      'groupInfoList',
      'groupTreeList'
    ]),
    ...mapState('userinfo/device', [
      'editMode',
      'groupId',
      'filterText',
      'multiSelectOnly',
      'selectedGroupList',
      'filterDeviceModels',
      'filterDeviceStates',
      'filterPublic',
      'filterLinkMode',
      // for multiMode
      'selectedDeviceList',
    ]),
    isMultiMode() {
      return this.editMode === euEditMode.multiSelect
    },
    filterDeviceList() {
      let needResult = true
      this.updateFilterResult(-1)

      // console.log(`[filterDeviceList] deviceInfoList:`, this.deviceInfoList.length)
      // 過濾 設備種類
      let deviceList = this.deviceInfoList.filter(
        (dev) =>
          dev.kind === euAccountKind.default ||
          dev.kind === euAccountKind.device
      )

      // 過濾勾選群組
      if (this.selectedGroupList?.length > 0) {
        deviceList = deviceList.filter((dev) => {
          return this.selectedGroupList.find((grpId) => grpId === dev.groupId)
        })
      } // 過濾單選群組
      else if (this.groupId) {
        deviceList = deviceList.filter((user) => {
          return this.groupId === user.groupId
        })
      }
      // console.log(`[filterDeviceList] #1`)

      // 過濾帳號或帳號名稱+影片標題
      if (this.filterText) {
        needResult = true
        const text = this.filterText.toLowerCase()
        deviceList = deviceList.filter(
          (dev) =>
            dev.id.toLowerCase().includes(text) ||
            dev.info.name.toLowerCase().includes(text) ||
            dev.video.title.toLowerCase().includes(text)
        )
      }
      // console.log(`[filterDeviceList] #2`)

      // 過濾設備類型
      if (this.filterDeviceModels.length > 0) {
        needResult = true
        deviceList = deviceList.filter((dev) => {

          return this.filterDeviceModels.includes(dev.deviceModelId)
        })
      }
      // console.log(`[filterDeviceList] #3`)

      // 過濾帳號狀態
      if (this.filterDeviceStates.length > 0) {
        needResult = true
        const stateFilter = {
          enabled: this.filterDeviceStates.includes('enabled') ? 1 : null,
          disable: this.filterDeviceStates.includes('disable') ? 0 : null,
          locked: this.filterDeviceStates.includes('locked') ? 1 : null
        }

        deviceList = deviceList.filter((dev) => {
          const { enabled, disable, locked } = stateFilter
          return (
            (enabled != null && dev.enabled === enabled) ||
            (disable != null && dev.enabled === disable) ||
            (locked != null && dev.locked === locked)
          )
        })
      }
      // console.log(`[filterDeviceList] #4`)

      // 過濾 公開私有
      if (this.filterPublic !== all) {
        needResult = true
        deviceList = deviceList.filter((dev) => {
          return dev.public === this.filterPublic
        })
      }
      // console.log(`[filterDeviceList] #5`)

      // 過濾 勾稽
      if (this.filterLinkMode !== all) {
        needResult = true
        deviceList = deviceList.filter((dev) => {
          return dev.linkedMode === this.filterLinkMode
        })
      }
      // console.log(`[filterDeviceList] #6`)

      // 更新篩選結果 (不需要將 多選帳號, 所選的帳號一起列入)
      if (needResult) {
        this.updateFilterResult(deviceList.length)
      }

      // 過濾是為勾選帳號
      let multiDeviceList = []
      const needFilterMulti = this.isMultiMode && this.multiSelectOnly
      if (needFilterMulti) {
        multiDeviceList = deviceList.filter(
          (dev) =>
            this.selectedDeviceList.filter(({ id }) => id === dev.id).length > 0
        )
      }
      // console.log(`[filterDeviceList] #7 deviceList:`, deviceList.length)
      // console.log(`[filterDeviceList] #7 multiDeviceList:`, multiDeviceList.length)

      return needFilterMulti ? multiDeviceList : deviceList
    },
    subGroupList() {
      // console.time('subGroupList time')
      let subTreeList = this.groupInfoList

      if (this.selectedGroupList?.length > 0) {
        subTreeList = subTreeList.filter((groupInfo) => {
          const checked = this.selectedGroupList.find(
            (groupId) => groupId === groupInfo.id
          )
          return checked ? true : false
        })
      } else {
        const groupId = this.groupId
          ? this.groupId
          : this.groupInfoList.length > 0
          ? this.groupInfoList[0].id
          : ''

        if (groupId) {
          subTreeList = getNodeFamily(this.groupTreeList, groupId)
        }
      }

      // 過濾掉空的群組
      subTreeList = this.getSubGroupList(subTreeList)

      // console.timeEnd('subGroupList time')
      return subTreeList
    }
  },
  methods: {
    ...mapMutations('userinfo/device', [
      'updateFilterResult',
      'clearSelectedDeviceList'
    ]),
    getSubGroupList(list) {
      let result = []
      const deviceGrpIdList = this.filterDeviceList.map((dev) => dev.groupId)

      result = list.filter((tlGrp) => {
        const hasDevice = deviceGrpIdList.includes(tlGrp.id)
        return hasDevice
      })

      return result
    },
    getFilterGroupDeviceList(groupId) {
      // if (this.groupId && groupId !== this.groupId) return []

      if (this.filterDeviceList.length <= 0) return []

      // 過濾 使用者群組
      const result = this.filterDeviceList.filter(
        (dev) => dev.groupId === groupId
      )

      return result
    }
  },
  watch: {
    // deviceInfoList: {
    //   deep: true,
    //   handler(nVal) {
    //     console.log(`Device.Table.watch.deviceInfoList:`, nVal.find((dev) => dev.id === 'keke1').locked)
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
$ActionIconW: 3%; // 1rem;
$ShortInfoW: 10%; // 5rem;
$LongInfoW: calc($ShortInfoW * 2.5);
$InfoPadding: 1rem;
$fontSize: px2rem(24);
.wrap-device-table {
  height: 100%;
  padding-bottom: calc(100vh * 0.01);
  background-color: #282942;

  .device-table {
    width: 100%;
    height: 100%;
    font-size: $fontSize;
    // line-height: px2rem(30);
    color: #ffffff;

    .header {
      // font-size: px2rem(20);

      .row {
        display: flex;
        align-items: center;
        margin: 0 px2rem(4);
        min-height: px2rem(50);
        // background-color: #f00;

        // &:hover {
        //   background: #6e7d9333;
        // }
        // &:active {
        //   background: #6e7d9399;
        // }

        .col {
          display: flex;
          font-weight: 500;
          margin-right: 0.4rem;
          padding-left: 0.5rem;
          width: px2rem(24); // 基準
          font-size: px2rem(20);
          // background-color: #00f;

          // 多選帳號 eraser
          &:first-child {
            margin-left: 0.75rem;
            padding-left: unset;
            visibility: hidden;
            img {
              height: $fontSize;
              width: $fontSize;
            }
          }
          &.eraser {
            cursor: pointer;
            visibility: visible;

            &.disabled {
              cursor: unset;
              opacity: 0.2;
            }
          }
          // 帳號(包含 帳號,公開 ICON)
          &:nth-child(2) {
            width: calc($LongInfoW + $ShortInfoW);
          }
          &:nth-child(3),
          &:nth-child(4) {
            width: $LongInfoW;
          }
          &:nth-child(5),
          &:nth-child(6) {
            width: $ShortInfoW;
          }
          // Lock, 多選帳號儲存狀態
          &:last-child {
            margin-right: unset;
          }

          // &.id {
          //   padding-left: px2rem(52);
          //   display: flex;
          //   align-items: center;

          //   img {
          //     margin-right: px2rem(12);
          //   }
          // }
        }
      }
    }

    .device-content {
      height: calc(100% - px2rem(50));
      overflow: overlay;
    }
  }
}
</style>
